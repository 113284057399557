// src/components/Sidebar.js

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import WhiteLogo from '../assets/logo_main_white.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { getAuth, signOut } from 'firebase/auth';
import { useUserContext } from '../context/UserContext';
import Spinner from './Spinner';
import ProfilePic from '../assets/default_profile.png';
import { AiOutlineSync } from 'react-icons/ai';
import { FaComments, FaInbox, FaHome, FaCalendarAlt, FaUsers, FaUser, FaBook } from 'react-icons/fa'; // Imported new icons
import Badge from '@mui/material/Badge';
import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
} from 'firebase/firestore';

const Sidebar = () => {
  const [utcTime, setUtcTime] = useState('');
  const {
    userData,
    setUserData,
    setClubData,
    setClubSelected,
    clubData,
  } = useUserContext();
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const profileRef = useRef(null);

  // New state variables for unread count
  const [unreadCount, setUnreadCount] = useState(0);
  const [allPosts, setAllPosts] = useState([]);
  const [readPostIds, setReadPostIds] = useState([]);
  const [memberDocId, setMemberDocId] = useState(null);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      const seconds = String(now.getUTCSeconds()).padStart(2, '0');
      setUtcTime(`${hours}:${minutes}:${seconds}`);
    };

    const intervalId = setInterval(updateTime, 1000);
    updateTime();

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch unread posts count
  useEffect(() => {
    if (!userData || !clubData) return;

    let unsubscribePosts;
    let unsubscribeMember;

    const fetchMemberDocId = async () => {
      try {
        // Query the members collection to find the document with uid equal to userData.uid
        const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
        const q = query(membersRef, where('uid', '==', userData.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const memberDoc = querySnapshot.docs[0];
          setMemberDocId(memberDoc.id);

          // Listen for updates to the member document
          const memberDocRef = doc(db, `clubs/${clubData.orgnr}/members`, memberDoc.id);
          unsubscribeMember = onSnapshot(memberDocRef, (docSnapshot) => {
            const memberData = docSnapshot.data();
            const readPosts = memberData?.readPosts || [];
            setReadPostIds(readPosts);
          });
        } else {
          console.error('Member document not found');
        }
      } catch (error) {
        console.error('Error fetching member document:', error);
      }
    };

    fetchMemberDocId();

    // Listen to posts collection
    const postsRef = collection(db, `clubs/${clubData.orgnr}/posts`);
    unsubscribePosts = onSnapshot(postsRef, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAllPosts(posts);
    });

    return () => {
      if (unsubscribePosts) unsubscribePosts();
      if (unsubscribeMember) unsubscribeMember();
    };
  }, [userData, clubData, db]);

  // Compute unread count whenever allPosts or readPostIds change
  useEffect(() => {
    const computeUnreadCount = () => {
      const unreadPosts = allPosts.filter((post) => !readPostIds.includes(post.id));
      setUnreadCount(unreadPosts.length);
    };
    computeUnreadCount();
  }, [allPosts, readPostIds]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
      setClubData(null);
      setClubSelected(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Handle Synchronize (Refresh Page)
  const handleSynchronize = () => {
    window.location.reload();
  };

  // Handle Chat Button Click
  const handleChatClick = () => {
    navigate('/chat');
  };

  // Handle Inbox Button Click
  const handleInboxClick = () => {
    navigate('/inbox');
  };

  // Disable body scroll when sidebar is open
  useEffect(() => {
    if (isMobile) {
      if (isSidebarOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }, [isSidebarOpen, isMobile]);

  if (!userData) {
    return <Spinner />;
  }

  // Get the position of the profile section
  const profilePosition = profileRef.current?.getBoundingClientRect();

  // Extended Profile Component
  const extendedProfile = (
    !isMobile && (
      <div
        className={`extended-profile ${isProfileExpanded ? 'expanded' : ''}`}
        onMouseEnter={() => setIsProfileExpanded(true)}
        onMouseLeave={() => setIsProfileExpanded(false)}
        style={{
          top: profilePosition ? profilePosition.top - 50 : 'auto', /* Moved further up */
        }}
      >
        <div className="extended-content">
          <p>
            Navn: {userData.firstName} {userData.lastName}
          </p>
          <p>E-post: {userData.email}</p>
          {/* Add more detailed info as needed */}
          <div className="extended-buttons">
            <button
              className="profile-page-button"
              onClick={() => navigate('/profile')}
            >
              Gå til Profil
            </button>
            <button
              className="synchronize-button"
              onClick={handleSynchronize}
            >
              <AiOutlineSync className="sync-icon" /> Synkroniser
            </button>
          </div>
        </div>
      </div>
    )
  );

  // Sidebar content without the footer
  const sidebarContent = (
    <>
      {!isMobile && (
        <div className="logo">
          <img width="200px" src={WhiteLogo} alt="AirTrakr" />
        </div>
      )}

      <nav className="nav">
        <NavLink
          to="/dashboard"
          className={({ isActive }) => (isActive ? 'nav-button selected' : 'nav-button')}
          onClick={() => isMobile && setIsSidebarOpen(false)}
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/booking"
          className={({ isActive }) => (isActive ? 'nav-button selected' : 'nav-button')}
          onClick={() => isMobile && setIsSidebarOpen(false)}
        >
          Booking
        </NavLink>
        <NavLink
          to="/logbook"
          className={({ isActive }) => (isActive ? 'nav-button selected' : 'nav-button')}
          onClick={() => isMobile && setIsSidebarOpen(false)}
        >
          Min Loggbok
        </NavLink>
        <NavLink
          to="/myclub"
          className={({ isActive }) => (isActive ? 'nav-button selected' : 'nav-button')}
          onClick={() => isMobile && setIsSidebarOpen(false)}
        >
          Min Klubb
        </NavLink>

        {userData?.role === 'admin' && (
          <NavLink
            to="/admin-controls"
            className={({ isActive }) => (isActive ? 'nav-button selected' : 'nav-button')}
            onClick={() => isMobile && setIsSidebarOpen(false)}
          >
            Administrer Klubb
          </NavLink>
        )}
      </nav>
    </>
  );

  // Footer section with UTC time, chat, and inbox icons
  const footerSection = (
    <div className="sidebar-footer">
      <meta name="theme-color" content="#f0f2f5" />
      <div className="icon-group">
        <Badge badgeContent={unreadCount} color="primary">
          <button className="alert-button" onClick={handleInboxClick} title="Innboks">
            <FaInbox className="alert-icon" />
          </button>
        </Badge>
        <Badge color="primary" badgeContent={0} max={9}>
          <button className="alert-button" onClick={handleChatClick} title="Meldinger">
            <FaComments className="alert-icon" />
          </button>
        </Badge>
      </div>
      <div className="divider-2"></div>
      <div className="utc-display">{utcTime}Z</div>
      <div className="divider"></div>
      <div
        className="profile"
        onMouseEnter={() => !isMobile && setIsProfileExpanded(true)}
        onMouseLeave={() => !isMobile && setIsProfileExpanded(false)}
        ref={profileRef}
      >
        <img src={ProfilePic} alt="" className="profile-pic" />
        <div className="profile-name">
          Hei,{' '}
          <strong>
            {userData.firstName} {userData.lastName}
          </strong>
          !
        </div>
        <button
          className="logout-button"
          onClick={() => {
            handleLogout();
            isMobile && setIsSidebarOpen(false);
          }}
        >
          Logg ut
        </button>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <div className="mobile-header">
            <meta name="theme-color" content="#192f3a" />
            <button className="burger-button" onClick={toggleSidebar}>
              <Badge badgeContent={unreadCount} color="primary">
                <RxHamburgerMenu />
              </Badge>
            </button>
            <img src={WhiteLogo} alt="AirTrakr" className="mobile-logo" />
          </div>
          <div className={`mobile-sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <div className="sidebar-content">{sidebarContent}</div>
            {footerSection}
          </div>
          {/* Bottom Navigation Bar */}
          <div className="bottom-nav">
            <NavLink to="/dashboard" className="nav-icon">
              <FaHome />
            </NavLink>
            <NavLink to="/booking" className="nav-icon">
              <FaCalendarAlt />
            </NavLink>
            <button className="nav-main-button" onClick={() => navigate('/logbook')}>
              <FaBook />
            </button>
            <NavLink to="/myclub" className="nav-icon">
              <FaUsers />
            </NavLink>
            <NavLink to="/chat" className="nav-icon">
              <FaComments  />
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="sidebar">
            <div className="sidebar-content">{sidebarContent}</div>
            {footerSection}
          </div>
          {extendedProfile}
        </>
      )}
    </>
  );
};

export default Sidebar;

// AdminControls.js

import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { useUserContext } from '../context/UserContext';
import Sidebar from './Sidebar';
import './AdminControls.css';
import Spinner from './Spinner';

import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import * as Showdown from 'showdown';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const AdminControls = () => {
  const { clubData } = useUserContext();
  const [members, setMembers] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [invites, setInvites] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('members');
  const [creatingPlane, setCreatingPlane] = useState(false);
  const [addingMember, setAddingMember] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [editingPostId, setEditingPostId] = useState(null);
  const [newMemberData, setNewMemberData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [newPlaneData, setNewPlaneData] = useState({
    callsign: '',
    color: '',
    engineType: '',
    year: '',
    manufacturer: '',
    model: '',
    category: '',
    type: '',
    ownerContact: '',
    ownerOrgNumber: '',
    registrationDate: '',
    airworthinessCategory: '',
    maxTakeoffMass: '',
    buildYear: '',
    serialNumber: '',
    owners: [
      {
        ownerType: '',
        ownerSince: '',
        name: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        country: '',
      },
    ],
  });
  const [newPostData, setNewPostData] = useState({
    title: '',
    content: '',
  });
  const [selectedTab, setSelectedTab] = useState('write');
  const [editingMemberRole, setEditingMemberRole] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [selectedPlane, setSelectedPlane] = useState(null);
  const [planeDialogOpen, setPlaneDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [lowkeyError, setLowkeyError] = useState('');

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
  });

  const db = getFirestore();
  const auth = getAuth();

  // Fetch data when component loads
  useEffect(() => {
    const fetchData = async () => {
      if (clubData) {
        try {
          const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
          const planesRef = collection(db, `clubs/${clubData.orgnr}/planes`);
          const invitesRef = collection(db, `invites`);
          const postsRef = collection(db, `clubs/${clubData.orgnr}/posts`);

          const [
            membersSnapshot,
            planesSnapshot,
            invitesSnapshot,
            postsSnapshot,
          ] = await Promise.all([
            getDocs(membersRef),
            getDocs(planesRef),
            getDocs(invitesRef),
            getDocs(postsRef),
          ]);

          setMembers(
            membersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
          setPlanes(
            planesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
          setInvites(
            invitesSnapshot.docs
              .map((doc) => ({ id: doc.id, ...doc.data() }))
              .filter((invite) => invite.clubId === clubData.orgnr)
          );
          setPosts(
            postsSnapshot.docs
              .map((doc) => ({ id: doc.id, ...doc.data() }))
              .sort((a, b) => {
                const dateA = a.createdAt?.toDate
                  ? a.createdAt.toDate()
                  : a.createdAt
                  ? new Date(a.createdAt.seconds * 1000)
                  : new Date();
                const dateB = b.createdAt?.toDate
                  ? b.createdAt.toDate()
                  : b.createdAt
                  ? new Date(b.createdAt.seconds * 1000)
                  : new Date();
                return dateB - dateA;
              })
          );
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [clubData, db]);

  // Handle input changes for plane data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPlaneData({ ...newPlaneData, [name]: value });
  };

  // Handle callsign input with auto-formatting
  const handleCallsignChange = (e) => {
    let { value } = e.target;
    value = value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Remove non-alphanumeric characters
    if (value.length > 2) {
      value = `${value.slice(0, 2)}-${value.slice(2, 5)}`;
    }
    setNewPlaneData({ ...newPlaneData, callsign: value });
  };

  // Handle member input changes
  const handleMemberInputChange = (e) => {
    const { name, value } = e.target;
    setNewMemberData({ ...newMemberData, [name]: value });
  };

  // Handle post title changes
  const handlePostTitleChange = (e) => {
    const { name, value } = e.target;
    setNewPostData({ ...newPostData, [name]: value });
  };

  // Validate plane form and set errors
  const validatePlaneForm = () => {
    const errors = {};
    if (!newPlaneData.callsign.trim()) errors.callsign = 'Kallesignal er påkrevd';
    if (!newPlaneData.color.trim()) errors.color = 'Farge er påkrevd';
    if (!newPlaneData.engineType.trim()) errors.engineType = 'Motortype er påkrevd';
    if (!newPlaneData.year.trim()) errors.year = 'Årsmodell er påkrevd';
    if (!newPlaneData.manufacturer.trim())
      errors.manufacturer = 'Produsent er påkrevd';
    if (!newPlaneData.model.trim()) errors.model = 'Modell er påkrevd';
    if (!newPlaneData.category.trim()) errors.category = 'Kategori er påkrevd';
    if (!newPlaneData.type.trim()) errors.type = 'Type er påkrevd';
    if (!newPlaneData.ownerContact.trim())
      errors.ownerContact = 'Eier/Kontakt er påkrevd';
    if (!newPlaneData.ownerOrgNumber.trim())
      errors.ownerOrgNumber = 'Organisasjonsnummer er påkrevd';
    if (!newPlaneData.registrationDate.trim())
      errors.registrationDate = 'Registreringsdato er påkrevd';
    if (!newPlaneData.airworthinessCategory.trim())
      errors.airworthinessCategory = 'Luftdyktighetskategori er påkrevd';
    if (!newPlaneData.maxTakeoffMass.trim())
      errors.maxTakeoffMass = 'Startmasse MTOM er påkrevd';
    if (!newPlaneData.buildYear.trim()) errors.buildYear = 'Byggeår er påkrevd';
    if (!newPlaneData.serialNumber.trim())
      errors.serialNumber = 'Serienummer er påkrevd';

    newPlaneData.owners.forEach((owner, index) => {
      if (!owner.ownerType.trim())
        errors[`ownerType_${index}`] = 'Eier type er påkrevd';
      if (!owner.ownerSince.trim())
        errors[`ownerSince_${index}`] = 'Eier siden er påkrevd';
      if (!owner.name.trim()) errors[`name_${index}`] = 'Navn er påkrevd';
      if (!owner.streetAddress.trim())
        errors[`streetAddress_${index}`] = 'Gateadresse er påkrevd';
      if (!owner.postalCode.trim())
        errors[`postalCode_${index}`] = 'Postnummer er påkrevd';
      if (!owner.city.trim()) errors[`city_${index}`] = 'Poststed er påkrevd';
      if (!owner.country.trim())
        errors[`country_${index}`] = 'Land er påkrevd';
    });

    setFormErrors(errors);
    const errorMessagesArray = Object.values(errors);
    setErrorMessages(errorMessagesArray);
    return Object.keys(errors).length === 0;
  };

  // Handle plane creation
  const handleCreatePlane = async () => {
    const isValid = validatePlaneForm();
    if (!isValid) {
      alert('Vennligst fyll ut alle påkrevde felter.');
      return;
    }

    try {
      const planeId = uuidv4();
      const planeRef = doc(db, `clubs/${clubData.orgnr}/planes`, planeId);

      await setDoc(planeRef, { ...newPlaneData, id: planeId });

      alert('Fly opprettet!');
      setCreatingPlane(false);
      setPlanes([...planes, { ...newPlaneData, id: planeId }]);
      setNewPlaneData({
        callsign: '',
        color: '',
        engineType: '',
        year: '',
        manufacturer: '',
        model: '',
        category: '',
        type: '',
        ownerContact: '',
        ownerOrgNumber: '',
        registrationDate: '',
        airworthinessCategory: '',
        maxTakeoffMass: '',
        buildYear: '',
        serialNumber: '',
        owners: [
          {
            ownerType: '',
            ownerSince: '',
            name: '',
            streetAddress: '',
            postalCode: '',
            city: '',
            country: '',
          },
        ],
      });
      setFormErrors({});
      setErrorMessages([]);
    } catch (error) {
      console.error('Error creating plane:', error);
    }
  };

  // Handle plane deletion
  const handleDeletePlane = async (planeId) => {
    try {
      await deleteDoc(doc(db, `clubs/${clubData.orgnr}/planes`, planeId));
      setPlanes(planes.filter((plane) => plane.id !== planeId));
      alert('Fly slettet!');
    } catch (error) {
      console.error('Error deleting plane:', error);
    }
  };

  // Handle member deletion
  const handleDeleteMember = async (memberId) => {
    try {
      await deleteDoc(doc(db, `clubs/${clubData.orgnr}/members`, memberId));
      setMembers(members.filter((member) => member.id !== memberId));
      alert('Medlem slettet!');
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  // Handle sending invite
  const handleSendInvite = async () => {
    const isMemberFormValid = Object.values(newMemberData).every(
      (value) => value.trim() !== ''
    );
    if (!isMemberFormValid) {
      alert('Vennligst fyll ut alle feltene.');
      return;
    }

    try {
      const inviteId = uuidv4();
      const inviteRef = doc(db, `invites/${inviteId}`);

      const inviteData = {
        firstName: newMemberData.firstName,
        lastName: newMemberData.lastName,
        email: newMemberData.email,
        clubId: clubData.orgnr,
        inviteId,
      };

      await setDoc(inviteRef, inviteData);

      // Send email via server API
      await fetch('https://airportinfo.airtrakr.com/api/send-invite', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName: newMemberData.firstName,
          lastName: newMemberData.lastName,
          email: newMemberData.email,
          club: clubData.navn,
          inviteLink: `${window.location.origin}/invites/${inviteId}`,
        }),
      });

      alert(`Invitasjon sendt til ${newMemberData.email}`);
      setAddingMember(false);
      setInvites([...invites, inviteData]);
      setNewMemberData({
        firstName: '',
        lastName: '',
        email: '',
      });
    } catch (error) {
      console.error('Error sending invite:', error);
      
    }
  };

  // Handle deleting an invite
  const handleDeleteInvite = async (inviteId) => {
    try {
      await deleteDoc(doc(db, `invites/${inviteId}`));
      setInvites(invites.filter((invite) => invite.inviteId !== inviteId));
      alert('Invitasjon slettet!');
    } catch (error) {
      console.error('Error deleting invite:', error);
    }
  };

  // Handle creating or updating a post
  const handleCreatePost = async () => {
    if (!newPostData.title.trim() || !newPostData.content.trim()) {
      alert('Vennligst fyll ut tittel og innhold.');
      return;
    }

    try {
      const postId = editingPostId || uuidv4();
      const postRef = doc(db, `clubs/${clubData.orgnr}/posts`, postId);

      const postData = {
        ...newPostData,
        id: postId,
        createdAt: editingPostId ? newPostData.createdAt : serverTimestamp(),
        notify: true,
      };

      await setDoc(postRef, postData);

      if (editingPostId) {
        setPosts(posts.map((post) => (post.id === postId ? postData : post)));
        alert('Innlegg oppdatert!');
      } else {
        setPosts([postData, ...posts]);
        alert('Innlegg opprettet!');
      }

      setCreatingPost(false);
      setEditingPostId(null);
      setNewPostData({
        title: '',
        content: '',
      });
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  // Handle deleting a post
  const handleDeletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, `clubs/${clubData.orgnr}/posts`, postId));
      setPosts(posts.filter((post) => post.id !== postId));
      alert('Innlegg slettet!');
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  // Handle editing a post
  const handleEditPost = (post) => {
    setNewPostData({
      title: post.title,
      content: post.content,
      createdAt: post.createdAt,
    });
    setEditingPostId(post.id);
    setCreatingPost(true);
  };

  // Set active section
  const handleSectionClick = (section) => {
    setActiveSection(section);
    setCreatingPlane(false);
    setAddingMember(false);
    setCreatingPost(false);
    setEditingPostId(null);
  };

  // Handle Lowkey button click
  const handleLowkey = async () => {
    setLowkeyError('');
    const registreringsmerke = newPlaneData.callsign;
    if (!registreringsmerke) {
      setLowkeyError('Vennligst fyll ut kallesignal først.');
      return;
    }

    try {
      const response = await fetch('https://airportinfo.airtrakr.com/api/aircrafts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Registreringsmerke: registreringsmerke }),
      });

      const data = await response.json();

      if (data.success) {
        const aircraft = data.aircraft;
        setNewPlaneData((prevData) => ({
          ...prevData,
          callsign: aircraft.Registreringsmerke || '',
          color: '', // Assuming color is not returned by the API
          engineType: '', // Assuming engineType is not returned by the API
          year: aircraft.Byggeår || '',
          manufacturer: aircraft.Produsent || '',
          model: aircraft.Type || '',
          category: aircraft.Kategori || '',
          type: aircraft.Type || '',
          ownerContact: aircraft['Eier/Kontakt'] || '',
          ownerOrgNumber: aircraft['Organisasjonsnummer eier/kontakt'] || '',
          registrationDate: aircraft.Registreringsdato || '',
          airworthinessCategory: aircraft.Luftdyktighetskategori
            ? aircraft.Luftdyktighetskategori.join(', ')
            : '',
          maxTakeoffMass: aircraft['Startmasse MTOM'] || '',
          buildYear: aircraft.Byggeår || '',
          serialNumber: aircraft.Serienummer || '',
          owners: Array.isArray(aircraft['Eier(e)'])
            ? aircraft['Eier(e)'].map((owner) => ({
                ownerType: owner['Eier type'] || '',
                ownerSince: owner['Eier siden'] || '',
                name: owner.Navn || '',
                streetAddress: owner.Gateadresse || '',
                postalCode: owner.Postnummer || '',
                city: owner.Poststed || '',
                country: owner.Land || '',
              }))
            : prevData.owners, // Keep previous owners if no valid "Eier" data exists
        }));
      } else {
        setLowkeyError(data.message || 'Ingen data funnet for dette kallesignalet.');
      }
    } catch (error) {
      console.error('Error fetching aircraft data:', error);
      setLowkeyError('En feil oppstod ved henting av data.');
    }
  };

  // Handle owner input changes
  const handleOwnerChange = (index, e) => {
    const { name, value } = e.target;
    const updatedOwners = newPlaneData.owners.map((owner, i) =>
      i === index ? { ...owner, [name]: value } : owner
    );
    setNewPlaneData({ ...newPlaneData, owners: updatedOwners });
  };

  // Add a new owner
  const addOwner = () => {
    setNewPlaneData({
      ...newPlaneData,
      owners: [
        ...newPlaneData.owners,
        {
          ownerType: '',
          ownerSince: '',
          name: '',
          streetAddress: '',
          postalCode: '',
          city: '',
          country: '',
        },
      ],
    });
  };

  // Remove an owner
  const removeOwner = (index) => {
    const updatedOwners = newPlaneData.owners.filter((_, i) => i !== index);
    setNewPlaneData({ ...newPlaneData, owners: updatedOwners });
  };

  // Handle editing member role
  const handleEditRole = (member) => {
    setEditingMemberRole(member);
    setNewRole(member.role);
  };

  // Handle saving new role
  const handleSaveRole = async () => {
    try {
      const memberId = editingMemberRole.id;
      const memberRef = doc(db, `clubs/${clubData.orgnr}/members`, memberId);

      await setDoc(memberRef, { ...editingMemberRole, role: newRole });

      setMembers(
        members.map((member) =>
          member.id === memberId ? { ...member, role: newRole } : member
        )
      );

      alert('Rolle oppdatert!');
      setEditingMemberRole(null);
    } catch (error) {
      console.error('Error updating member role:', error);
      alert('En feil oppstod ved oppdatering av rolle.');
    }
  };

  // Handle closing the role dialog
  const handleCloseRoleDialog = () => {
    setEditingMemberRole(null);
  };

  // Handle clicking on a plane row
  const handlePlaneRowClick = (plane) => {
    setSelectedPlane(plane);
    setPlaneDialogOpen(true);
  };

  // Close plane dialog
  const handleClosePlaneDialog = () => {
    setPlaneDialogOpen(false);
    setSelectedPlane(null);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="admin-controls">
      <div className="admin-controls-container">
        <Sidebar />
        <div className="admin-navbar">
          <button
            className={`nav-item ${activeSection === 'members' ? 'active' : ''}`}
            onClick={() => handleSectionClick('members')}
          >
            Medlemsstyring
          </button>
          <button
            className={`nav-item ${activeSection === 'planes' ? 'active' : ''}`}
            onClick={() => handleSectionClick('planes')}
          >
            Flystyring
          </button>
          <button
            className={`nav-item ${activeSection === 'messages' ? 'active' : ''}`}
            onClick={() => handleSectionClick('messages')}
          >
            Meldingsutsending
          </button>
        </div>

        {/* Manage Members Section */}
        {activeSection === 'members' && (
          <div className="members-section">
            <div className="admin-heading">
              Administrer medlemmer i {clubData.navn}
            </div>

            {addingMember ? (
              <div className="add-member-form">
                <h3>Inviter nytt medlem</h3>
                <div className="form-group">
                  <label>Fornavn</label>
                  <input
                    type="text"
                    name="firstName"
                    value={newMemberData.firstName}
                    onChange={handleMemberInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Etternavn</label>
                  <input
                    type="text"
                    name="lastName"
                    value={newMemberData.lastName}
                    onChange={handleMemberInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>E-postadresse</label>
                  <input
                    type="email"
                    name="email"
                    value={newMemberData.email}
                    onChange={handleMemberInputChange}
                    required
                  />
                </div>
                <div className="button-group">
                  <button className="submit-button" onClick={handleSendInvite}>
                    Send invitasjon
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => setAddingMember(false)}
                  >
                    Tilbake
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h3>Medlemsliste</h3>
                <table className="members-table">
                  <thead>
                    <tr>
                      <th>Navn</th>
                      <th>E-post</th>
                      <th>Rolle</th>
                      <th>Handlinger</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member) => (
                      <tr key={member.id}>
                        <td>
                          {member.firstName} {member.lastName}
                        </td>
                        <td>{member.email}</td>
                        <td>{member.role}</td>
                        <td>
                          <div className="button-container">
                            {member.role !== 'admin' && (
                              <>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleEditRole(member)}
                                  className="edit-role-button"
                                >
                                  Endre rolle
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleDeleteMember(member.id)}
                                  className="delete-button"
                                >
                                  Slett medlem
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* MUI Dialog for Editing Member Role */}
                <Dialog
                  open={Boolean(editingMemberRole)}
                  onClose={handleCloseRoleDialog}
                >
                  <DialogTitle>
                    Endre rolle for {editingMemberRole?.firstName}{' '}
                    {editingMemberRole?.lastName}
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      select
                      label="Rolle"
                      value={newRole}
                      onChange={(e) => setNewRole(e.target.value)}
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="member">Medlem</MenuItem>
                      <MenuItem value="instructor">Instruktør</MenuItem>
                      {/* Exclude 'admin' from options */}
                    </TextField>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseRoleDialog} color="secondary">
                      Avbryt
                    </Button>
                    <Button onClick={handleSaveRole} color="primary">
                      Lagre
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Invite List Section */}
                <h3>Inviterte medlemmer</h3>
                <table className="members-table">
                  <thead>
                    <tr>
                      <th>Fornavn</th>
                      <th>Etternavn</th>
                      <th>E-post</th>
                      <th>Invitasjonslenke</th>
                      <th>Handlinger</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invites.map((invite) => (
                      <tr key={invite.inviteId}>
                        <td>{invite.firstName}</td>
                        <td>{invite.lastName}</td>
                        <td>{invite.email}</td>
                        <td>
                          <a
                            href={`${window.location.origin}/invites/${invite.inviteId}`}
                          >
                            {`${window.location.origin}/invites/${invite.inviteId}`}
                          </a>
                        </td>
                        <td>
                          <div className="button-container">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteInvite(invite.inviteId)}
                              className="delete-button"
                            >
                              Slett invitasjon
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Button to add new member */}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setAddingMember(true)}
                  className="create-member-button"
                >
                  Inviter nytt medlem
                </Button>
              </>
            )}
          </div>
        )}

        {/* Manage Planes Section */}
        {activeSection === 'planes' && (
          <div className="planes-section">
            <div className="admin-heading">Administrer fly</div>

            {creatingPlane ? (
              <div className="plane-creation-form">
                <h3>Opprett nytt fly</h3>
                {/* Plane creation form */}
                <div className="form-group">
                  <label>Kallesignal</label>
                  <div className="callsign-row">
                    <input
                      type="text"
                      name="callsign"
                      value={newPlaneData.callsign}
                      onChange={handleCallsignChange}
                      required
                      className={formErrors.callsign ? 'error' : ''}
                    />
                    <button className="lowkey-button" onClick={handleLowkey}>
                      Søk etter fly
                    </button>
                    {lowkeyError && (
                      <span className="error-message">{lowkeyError}</span>
                    )}
                  </div>
                  {formErrors.callsign && (
                    <span className="error-message">{formErrors.callsign}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Farge</label>
                  <input
                    type="text"
                    name="color"
                    value={newPlaneData.color}
                    onChange={handleInputChange}
                    required
                    className={formErrors.color ? 'error' : ''}
                  />
                  {formErrors.color && (
                    <span className="error-message">{formErrors.color}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Motortype</label>
                  <select
                    name="engineType"
                    value={newPlaneData.engineType}
                    onChange={handleInputChange}
                    required
                    className={formErrors.engineType ? 'error' : ''}
                  >
                    <option value="">Velg motortype</option>
                    <option value="Piston Engine">Piston Engine</option>
                    <option value="Turbojet Engine">Turbojet Engine</option>
                    <option value="Turboprop Engine">Turboprop Engine</option>
                    <option value="Turboshaft Engine">Turboshaft Engine</option>
                    <option value="Turbofan Engine">Turbofan Engine</option>
                  </select>
                  {formErrors.engineType && (
                    <span className="error-message">{formErrors.engineType}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Årsmodell</label>
                  <input
                    type="number"
                    name="year"
                    value={newPlaneData.year}
                    onChange={handleInputChange}
                    required
                    className={formErrors.year ? 'error' : ''}
                  />
                  {formErrors.year && (
                    <span className="error-message">{formErrors.year}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Produsent</label>
                  <input
                    type="text"
                    name="manufacturer"
                    value={newPlaneData.manufacturer}
                    onChange={handleInputChange}
                    required
                    className={formErrors.manufacturer ? 'error' : ''}
                  />
                  {formErrors.manufacturer && (
                    <span className="error-message">{formErrors.manufacturer}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Modell</label>
                  <input
                    type="text"
                    name="model"
                    value={newPlaneData.model}
                    onChange={handleInputChange}
                    required
                    className={formErrors.model ? 'error' : ''}
                  />
                  {formErrors.model && (
                    <span className="error-message">{formErrors.model}</span>
                  )}
                </div>

                {/* Additional Aircraft Information */}
                <div className="form-group">
                  <label>Kategori</label>
                  <input
                    type="text"
                    name="category"
                    value={newPlaneData.category}
                    onChange={handleInputChange}
                    required
                    className={formErrors.category ? 'error' : ''}
                  />
                  {formErrors.category && (
                    <span className="error-message">{formErrors.category}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Type</label>
                  <input
                    type="text"
                    name="type"
                    value={newPlaneData.type}
                    onChange={handleInputChange}
                    required
                    className={formErrors.type ? 'error' : ''}
                  />
                  {formErrors.type && (
                    <span className="error-message">{formErrors.type}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Eier/Kontakt</label>
                  <input
                    type="text"
                    name="ownerContact"
                    value={newPlaneData.ownerContact}
                    onChange={handleInputChange}
                    required
                    className={formErrors.ownerContact ? 'error' : ''}
                  />
                  {formErrors.ownerContact && (
                    <span className="error-message">
                      {formErrors.ownerContact}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label>Organisasjonsnummer eier/kontakt</label>
                  <input
                    type="text"
                    name="ownerOrgNumber"
                    value={newPlaneData.ownerOrgNumber}
                    onChange={handleInputChange}
                    required
                    className={formErrors.ownerOrgNumber ? 'error' : ''}
                  />
                  {formErrors.ownerOrgNumber && (
                    <span className="error-message">
                      {formErrors.ownerOrgNumber}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label>Registreringsdato</label>
                  <input
                    type="date"
                    name="registrationDate"
                    value={newPlaneData.registrationDate}
                    onChange={handleInputChange}
                    required
                    className={formErrors.registrationDate ? 'error' : ''}
                  />
                  {formErrors.registrationDate && (
                    <span className="error-message">
                      {formErrors.registrationDate}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label>Luftdyktighetskategori</label>
                  <input
                    type="text"
                    name="airworthinessCategory"
                    value={newPlaneData.airworthinessCategory}
                    onChange={handleInputChange}
                    required
                    className={formErrors.airworthinessCategory ? 'error' : ''}
                  />
                  {formErrors.airworthinessCategory && (
                    <span className="error-message">
                      {formErrors.airworthinessCategory}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label>Startmasse MTOM</label>
                  <input
                    type="number"
                    name="maxTakeoffMass"
                    value={newPlaneData.maxTakeoffMass}
                    onChange={handleInputChange}
                    required
                    className={formErrors.maxTakeoffMass ? 'error' : ''}
                  />
                  {formErrors.maxTakeoffMass && (
                    <span className="error-message">
                      {formErrors.maxTakeoffMass}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label>Byggeår</label>
                  <input
                    type="number"
                    name="buildYear"
                    value={newPlaneData.buildYear}
                    onChange={handleInputChange}
                    required
                    className={formErrors.buildYear ? 'error' : ''}
                  />
                  {formErrors.buildYear && (
                    <span className="error-message">{formErrors.buildYear}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>Serienummer</label>
                  <input
                    type="text"
                    name="serialNumber"
                    value={newPlaneData.serialNumber}
                    onChange={handleInputChange}
                    required
                    className={formErrors.serialNumber ? 'error' : ''}
                  />
                  {formErrors.serialNumber && (
                    <span className="error-message">
                      {formErrors.serialNumber}
                    </span>
                  )}
                </div>

                {/* Owners Section */}
                <div className="owners-section">
                  <h4>Eier(e)</h4>
                  {newPlaneData.owners.map((owner, index) => (
                    <div key={index} className="owner-form">
                      <h5>Eier {index + 1}</h5>
                      <div className="form-group">
                        <label>Eier type</label>
                        <input
                          type="text"
                          name="ownerType"
                          value={owner.ownerType}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={
                            formErrors[`ownerType_${index}`] ? 'error' : ''
                          }
                        />
                        {formErrors[`ownerType_${index}`] && (
                          <span className="error-message">
                            {formErrors[`ownerType_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Eier siden</label>
                        <input
                          type="date"
                          name="ownerSince"
                          value={owner.ownerSince}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={
                            formErrors[`ownerSince_${index}`] ? 'error' : ''
                          }
                        />
                        {formErrors[`ownerSince_${index}`] && (
                          <span className="error-message">
                            {formErrors[`ownerSince_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Navn</label>
                        <input
                          type="text"
                          name="name"
                          value={owner.name}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={formErrors[`name_${index}`] ? 'error' : ''}
                        />
                        {formErrors[`name_${index}`] && (
                          <span className="error-message">
                            {formErrors[`name_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Gateadresse</label>
                        <input
                          type="text"
                          name="streetAddress"
                          value={owner.streetAddress}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={
                            formErrors[`streetAddress_${index}`] ? 'error' : ''
                          }
                        />
                        {formErrors[`streetAddress_${index}`] && (
                          <span className="error-message">
                            {formErrors[`streetAddress_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Postnummer</label>
                        <input
                          type="text"
                          name="postalCode"
                          value={owner.postalCode}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={
                            formErrors[`postalCode_${index}`] ? 'error' : ''
                          }
                        />
                        {formErrors[`postalCode_${index}`] && (
                          <span className="error-message">
                            {formErrors[`postalCode_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Poststed</label>
                        <input
                          type="text"
                          name="city"
                          value={owner.city}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={formErrors[`city_${index}`] ? 'error' : ''}
                        />
                        {formErrors[`city_${index}`] && (
                          <span className="error-message">
                            {formErrors[`city_${index}`]}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Land</label>
                        <input
                          type="text"
                          name="country"
                          value={owner.country}
                          onChange={(e) => handleOwnerChange(index, e)}
                          required
                          className={
                            formErrors[`country_${index}`] ? 'error' : ''
                          }
                        />
                        {formErrors[`country_${index}`] && (
                          <span className="error-message">
                            {formErrors[`country_${index}`]}
                          </span>
                        )}
                      </div>
                      {newPlaneData.owners.length > 1 && (
                        <button
                          className="remove-owner-button"
                          onClick={() => removeOwner(index)}
                        >
                          Fjern Eier
                        </button>
                      )}
                      <hr />
                    </div>
                  ))}
                  <button className="add-owner-button" onClick={addOwner}>
                    Legg til Eier
                  </button>
                </div>

                {/* Error Messages Over Buttons */}
                {errorMessages.length > 0 && (
                  <div className="error-messages">
                    {errorMessages.map((message, index) => (
                      <p key={index} className="error-message">
                        {message}
                      </p>
                    ))}
                  </div>
                )}

                <div className="button-group">
                  <button className="submit-button" onClick={handleCreatePlane}>
                    Opprett fly
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => {
                      setCreatingPlane(false);
                      setFormErrors({});
                      setErrorMessages([]);
                    }}
                  >
                    Tilbake
                  </button>
                </div>
              </div>
            ) : (
              <>
                {/* List of Planes */}
                <div className="planes-list">
                  <h3>Fly i klubben</h3>
                  <table className="members-table">
                    <thead>
                      <tr>
                        <th>Kallesignal</th>
                        <th>Farge</th>
                        <th>Motor</th>
                        <th>Årsmodell</th>
                        <th>Produsent</th>
                        <th>Modell</th>
                        <th>Handlinger</th>
                      </tr>
                    </thead>
                    <tbody>
                      {planes.map((plane) => (
                        <tr
                          key={plane.id}
                          onClick={() => handlePlaneRowClick(plane)}
                          className="clickable-row"
                        >
                          <td>{plane.callsign}</td>
                          <td>{plane.color}</td>
                          <td>{plane.engineType}</td>
                          <td>{plane.year}</td>
                          <td>{plane.manufacturer}</td>
                          <td>{plane.model}</td>
                          <td>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePlane(plane.id);
                              }}
                              className="delete-button"
                            >
                              Slett fly
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Plane Details Dialog */}
                <Dialog
                  open={planeDialogOpen}
                  onClose={handleClosePlaneDialog}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>
                    {selectedPlane?.callsign} - {selectedPlane?.model}
                  </DialogTitle>
                  <DialogContent>
                    {selectedPlane && (
                      <div className="plane-details">
                        <p>
                          <strong>Kallesignal:</strong> {selectedPlane.callsign}
                        </p>
                        <p>
                          <strong>Farge:</strong> {selectedPlane.color}
                        </p>
                        <p>
                          <strong>Motortype:</strong> {selectedPlane.engineType}
                        </p>
                        <p>
                          <strong>Årsmodell:</strong> {selectedPlane.year}
                        </p>
                        <p>
                          <strong>Produsent:</strong> {selectedPlane.manufacturer}
                        </p>
                        <p>
                          <strong>Modell:</strong> {selectedPlane.model}
                        </p>
                        <p>
                          <strong>Kategori:</strong> {selectedPlane.category}
                        </p>
                        <p>
                          <strong>Type:</strong> {selectedPlane.type}
                        </p>
                        <p>
                          <strong>Eier/Kontakt:</strong> {selectedPlane.ownerContact}
                        </p>
                        <p>
                          <strong>Organisasjonsnummer eier/kontakt:</strong>{' '}
                          {selectedPlane.ownerOrgNumber}
                        </p>
                        <p>
                          <strong>Registreringsdato:</strong>{' '}
                          {selectedPlane.registrationDate}
                        </p>
                        <p>
                          <strong>Luftdyktighetskategori:</strong>{' '}
                          {selectedPlane.airworthinessCategory}
                        </p>
                        <p>
                          <strong>Startmasse MTOM:</strong>{' '}
                          {selectedPlane.maxTakeoffMass}
                        </p>
                        <p>
                          <strong>Byggeår:</strong> {selectedPlane.buildYear}
                        </p>
                        <p>
                          <strong>Serienummer:</strong> {selectedPlane.serialNumber}
                        </p>
                        <h4>Eiere:</h4>
                        {selectedPlane.owners.map((owner, index) => (
                          <div key={index}>
                            <p>
                              <strong>Eier {index + 1}:</strong>
                            </p>
                            <p>
                              <strong>Eier type:</strong> {owner.ownerType}
                            </p>
                            <p>
                              <strong>Eier siden:</strong> {owner.ownerSince}
                            </p>
                            <p>
                              <strong>Navn:</strong> {owner.name}
                            </p>
                            <p>
                              <strong>Gateadresse:</strong> {owner.streetAddress}
                            </p>
                            <p>
                              <strong>Postnummer:</strong> {owner.postalCode}
                            </p>
                            <p>
                              <strong>Poststed:</strong> {owner.city}
                            </p>
                            <p>
                              <strong>Land:</strong> {owner.country}
                            </p>
                            <hr />
                          </div>
                        ))}
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClosePlaneDialog} color="primary">
                      Lukk
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Button to create a new plane */}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreatingPlane(true)}
                  className="create-plane-button"
                >
                  Opprett nytt fly
                </Button>
              </>
            )}
          </div>
        )}

        {/* Message Sending Section */}
        {activeSection === 'messages' && (
          <div className="messages-section">
            <div className="admin-heading">Klubbnyheter</div>

            {creatingPost ? (
              <div className="create-post-form">
                <h3>{editingPostId ? 'Rediger innlegg' : 'Opprett nytt innlegg'}</h3>
                <div className="form-group">
                  <label>Tittel</label>
                  <input
                    type="text"
                    name="title"
                    value={newPostData.title}
                    onChange={handlePostTitleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Innhold (Markdown støttes)</label>
                  <ReactMde
                    value={newPostData.content}
                    onChange={(value) =>
                      setNewPostData({ ...newPostData, content: value })
                    }
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                </div>
                <div className="button-group">
                  <button className="submit-button" onClick={handleCreatePost}>
                    {editingPostId ? 'Oppdater innlegg' : 'Opprett innlegg'}
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => {
                      setCreatingPost(false);
                      setEditingPostId(null);
                      setNewPostData({
                        title: '',
                        content: '',
                      });
                    }}
                  >
                    Tilbake
                  </button>
                </div>
              </div>
            ) : (
              <>
                {/* List of Posts */}
                <div className="posts-list">
                  <h3>Tidligere innlegg</h3>
                  {posts.length === 0 ? (
                    <p>Ingen innlegg funnet.</p>
                  ) : (
                    <table className="members-table">
                      <thead>
                        <tr>
                          <th>Tittel</th>
                          <th>Publisert</th>
                          <th>Handlinger</th>
                        </tr>
                      </thead>
                      <tbody>
                        {posts.map((post) => {
                          const createdAtDate = post.createdAt?.toDate
                            ? post.createdAt.toDate()
                            : new Date(post.createdAt.seconds * 1000);
                          return (
                            <tr key={post.id}>
                              <td>{post.title}</td>
                              <td>{createdAtDate.toLocaleString()}</td>
                              <td>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleEditPost(post)}
                                  className="edit-button"
                                >
                                  Rediger
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleDeletePost(post.id)}
                                  className="delete-button"
                                >
                                  Slett
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>

                {/* Button to create a new post */}
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreatingPost(true)}
                  className="create-post-button"
                >
                  Opprett nytt innlegg
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminControls;

import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar'; // Ensure this component exists or replace with your own
import './Chat.css'; // Import the CSS file
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import { useUserContext } from '../context/UserContext'; // Replace with your own context if necessary
import { auth, db } from '../firebase/firebase'; // Firebase configuration
import { FiPlus, FiMoreVertical } from 'react-icons/fi';
import { format } from 'date-fns';

const Chat = () => {
  const { clubData } = useUserContext(); // Context providing club data
  const currentUser = auth.currentUser; // Current authenticated user

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [clubMembers, setClubMembers] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showMemberList, setShowMemberList] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const messagesEndRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [showChatOverlay, setShowChatOverlay] = useState(false);

  // For draggable overlay
  const [overlayStyle, setOverlayStyle] = useState({});
  const overlayRef = useRef(null);
  const startYRef = useRef(0);
  const currentYRef = useRef(0);
  const translateYRef = useRef(0);
  const isDraggingRef = useRef(false);

  const clubId = clubData?.orgnr; // Club identifier

  // Detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch all members
  useEffect(() => {
    if (!clubId) return;

    const membersRef = collection(db, 'clubs', clubId, 'members');
    const unsubscribe = onSnapshot(membersRef, (snapshot) => {
      const fetchedMembers = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setClubMembers(fetchedMembers);
    });

    return () => unsubscribe();
  }, [clubId]);

  // Fetch all chats
  useEffect(() => {
    if (!currentUser || !clubId) return;

    const chatsRef = collection(db, 'clubs', clubId, 'chats');
    const q = query(chatsRef, where('participants', 'array-contains', currentUser.uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedChats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChats(fetchedChats);
    });

    return () => unsubscribe();
  }, [currentUser, clubId]);

  // Fetch messages for selected chat
  useEffect(() => {
    if (!selectedChat || !clubId) return;

    const messagesRef = collection(db, 'clubs', clubId, 'chats', selectedChat.id, 'messages');
    const q = query(messagesRef, orderBy('createdAt', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [selectedChat, clubId]);

  // Scroll to bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Start a new chat
  const handleStartChat = async (member) => {
    if (!currentUser || !clubId) return;

    if (member.uid === currentUser.uid) {
      alert('Du kan ikke starte en chat med deg selv.');
      return;
    }

    const existingChat = chats.find(
      (chat) =>
        chat.participants.includes(member.uid) &&
        chat.participants.includes(currentUser.uid)
    );

    if (existingChat) {
      setSelectedChat(existingChat);
      if (isMobileView) setShowChatOverlay(true);
    } else {
      const chatData = {
        participants: [currentUser.uid, member.uid],
        createdAt: serverTimestamp(),
        lastMessage: '',
        lastMessageTime: serverTimestamp(),
      };

      try {
        const chatsRef = collection(db, 'clubs', clubId, 'chats');
        const chatDocRef = await addDoc(chatsRef, chatData);
        const newChat = { id: chatDocRef.id, ...chatData };
        setChats((prevChats) => [newChat, ...prevChats]);
        setSelectedChat(newChat);
        if (isMobileView) setShowChatOverlay(true);
      } catch (error) {
        console.error('Error creating chat:', error);
      }
    }
    setShowMemberList(false);
  };

  // Select a chat
  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    if (isMobileView) {
      setShowChatOverlay(true);
    }
  };

  // Send or edit a message
  const handleSendMessage = async () => {
    if (!messageInput.trim() || !selectedChat || !clubId || !currentUser) return;

    const messagesRef = collection(db, 'clubs', clubId, 'chats', selectedChat.id, 'messages');

    if (editingMessage) {
      const messageRef = doc(messagesRef, editingMessage.id);
      await updateDoc(messageRef, {
        text: messageInput.trim(),
        lastEditedAt: serverTimestamp(),
      });
      setEditingMessage(null);
    } else {
      const messageData = {
        text: messageInput.trim(),
        senderUid: currentUser.uid,
        createdAt: serverTimestamp(),
        isDeleted: false,
      };

      await addDoc(messagesRef, messageData);

      const chatRef = doc(db, 'clubs', clubId, 'chats', selectedChat.id);
      await updateDoc(chatRef, {
        lastMessage: messageInput.trim(),
        lastMessageTime: serverTimestamp(),
      });
    }
    setMessageInput('');
  };

  // Delete a message
  const handleDeleteMessage = async (message) => {
    const messageRef = doc(
      db,
      'clubs',
      clubId,
      'chats',
      selectedChat.id,
      'messages',
      message.id
    );
    await updateDoc(messageRef, {
      isDeleted: true,
    });
    setDropdownVisible({});
    if (message.id === messages[messages.length - 1].id) {
      const chatRef = doc(db, 'clubs', clubId, 'chats', selectedChat.id);
      await updateDoc(chatRef, {
        lastMessage: 'Meldingen har blitt slettet',
        lastMessageTime: serverTimestamp(),
      });
    }
  };

  // Edit a message
  const handleEditMessage = (message) => {
    setEditingMessage(message);
    setMessageInput(message.text);
    setDropdownVisible({});
  };

  // Toggle dropdown
  const toggleDropdown = (messageId) => {
    setDropdownVisible((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  // Get participant name
  const getParticipantName = (participants) => {
    const otherUid = participants.find((uid) => uid !== currentUser.uid);
    const member = clubMembers.find((member) => member.uid === otherUid);
    return member ? `${member.firstName} ${member.lastName}` : 'Ukjent';
  };

  // Format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    let date;

    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (timestamp.seconds && timestamp.nanoseconds) {
      date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    } else if (timestamp.toDate) {
      date = timestamp.toDate();
    } else if (typeof timestamp === 'number' || typeof timestamp === 'string') {
      date = new Date(timestamp);
    } else {
      return '';
    }

    if (isNaN(date.getTime())) {
      return '';
    }

    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    return isToday ? format(date, 'HH:mm') : format(date, 'dd.MM.yyyy');
  };

  // Close chat overlay
  const handleCloseOverlay = () => {
    setShowChatOverlay(false);
  };

  // Touch event handlers for draggable overlay
  const handleTouchStart = (e) => {
    startYRef.current = e.touches[0].clientY;
    isDraggingRef.current = true;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    currentYRef.current = e.touches[0].clientY;
    translateYRef.current = Math.max(0, currentYRef.current - startYRef.current);
    setOverlayStyle({
      transform: `translateY(${translateYRef.current}px)`,
      transition: 'none',
    });
  };

  const handleTouchEnd = () => {
    isDraggingRef.current = false;
    if (translateYRef.current > 150) {
      // Threshold to dismiss overlay
      setShowChatOverlay(false);
    } else {
      // Reset position
      setOverlayStyle({
        transform: 'translateY(0)',
        transition: 'transform 0.3s ease',
      });
    }
    translateYRef.current = 0;
  };

  return (
    <div className="chat-wrapper">
      <Sidebar />
      <div className="chat-container">
        {/* Chat List Section */}
        <div className="chat-list-container">
          <div className="chat-header-bar">
            <input
              type="text"
              placeholder="Søk i chatter"
              className="chat-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="chat-new-button" onClick={() => setShowMemberList(true)}>
              <FiPlus size={24} />
            </button>
          </div>
          <div className="chat-list">
            {chats.map((chat) => (
              <div
                key={chat.id}
                className={`chat-card ${selectedChat?.id === chat.id ? 'selected-chat' : ''}`}
                onClick={() => handleSelectChat(chat)}
              >
                <div className="chat-card-name">{getParticipantName(chat.participants)}</div>
                <div className="chat-card-last-message">
                  {chat.lastMessage || 'Ingen meldinger ennå.'}
                </div>
                <div className="chat-card-time">
                  {chat.lastMessageTime ? formatTimestamp(chat.lastMessageTime) : ''}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Window Section */}
        {!isMobileView && (
          <div className="chat-window">
            {selectedChat ? (
              <div className="chat-content">
                <div className="chat-header">
                  {getParticipantName(selectedChat.participants)}
                </div>
                <div className="chat-messages">
                  {messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`chat-message ${
                        msg.senderUid === currentUser.uid ? 'my-message' : 'other-message'
                      }`}
                    >
                      <div className="message-bubble">
                        <div className="chat-message-text">
                          {msg.isDeleted ? (
                            <i>Meldingen har blitt slettet</i>
                          ) : (
                            <>
                              {msg.text}
                              {msg.lastEditedAt && (
                                <span className="edited-tag"> (Redigert)</span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="message-info">
                          <div className="chat-message-time">
                            {msg.createdAt ? formatTimestamp(msg.createdAt) : ''}
                          </div>
                          {msg.senderUid === currentUser.uid && !msg.isDeleted && (
                            <div className="message-options">
                              <FiMoreVertical
                                className="options-icon"
                                onClick={() => toggleDropdown(msg.id)}
                              />
                              {dropdownVisible[msg.id] && (
                                <div className="dropdown-menu">
                                  <button onClick={() => handleEditMessage(msg)}>Rediger</button>
                                  <button onClick={() => handleDeleteMessage(msg)}>Slett</button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="chat-input-container">
                  <input
                    type="text"
                    placeholder="Skriv en melding..."
                    className="chat-input"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                  />
                  <button className="chat-send-button" onClick={handleSendMessage}>
                    {editingMessage ? 'Endre' : 'Send'}
                  </button>
                </div>
              </div>
            ) : (
              <div className="chat-placeholder">Velg en chat for å begynne å snakke!</div>
            )}
          </div>
        )}
      </div>

      {/* Overlay Background */}
      {isMobileView && showChatOverlay && (
        <div className="overlay-background" onClick={handleCloseOverlay}></div>
      )}

      {/* Chat Overlay for Mobile */}
      {isMobileView && showChatOverlay && (
        <div
          className="chat-overlay"
          style={overlayStyle}
          ref={overlayRef}
          // Move touch events to header for better UX
        >
          <div
            className="chat-overlay-header"
            onClick={handleCloseOverlay}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="overlay-handle"></div>
            <div className="overlay-participant-name">
              {getParticipantName(selectedChat.participants)}
            </div>
          </div>
          <div className="chat-overlay-content">
            <div className="chat-messages">
              {messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`chat-message ${
                    msg.senderUid === currentUser.uid ? 'my-message' : 'other-message'
                  }`}
                >
                  <div className="message-bubble">
                    <div className="chat-message-text">
                      {msg.isDeleted ? (
                        <i>Meldingen har blitt slettet</i>
                      ) : (
                        <>
                          {msg.text}
                          {msg.lastEditedAt && (
                            <span className="edited-tag"> (Redigert)</span>
                          )}
                        </>
                      )}
                    </div>
                    <div className="message-info">
                      <div className="chat-message-time">
                        {msg.createdAt ? formatTimestamp(msg.createdAt) : ''}
                      </div>
                      {msg.senderUid === currentUser.uid && !msg.isDeleted && (
                        <div className="message-options">
                          <FiMoreVertical
                            className="options-icon"
                            onClick={() => toggleDropdown(msg.id)}
                          />
                          {dropdownVisible[msg.id] && (
                            <div className="dropdown-menu">
                              <button onClick={() => handleEditMessage(msg)}>Rediger</button>
                              <button onClick={() => handleDeleteMessage(msg)}>Slett</button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-input-container">
              <input
                type="text"
                placeholder="Skriv en melding..."
                className="chat-input"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button className="chat-send-button" onClick={handleSendMessage}>
                {editingMessage ? 'Endre' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Member List Modal */}
      {showMemberList && (
        <div className="member-list-overlay">
          <div className="member-list-container">
            <div className="member-list-header">
              <h2>Velg medlem å chatte med</h2>
              <button className="close-button" onClick={() => setShowMemberList(false)}>
                ×
              </button>
            </div>
            <input
              type="text"
              placeholder="Søk etter medlemmer"
              className="member-search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="member-list">
              {clubMembers
                .filter((member) =>
                  `${member.firstName} ${member.lastName}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((member) => (
                  <div
                    key={member.uid}
                    className="member-list-item"
                    onClick={() => handleStartChat(member)}
                  >
                    {member.firstName} {member.lastName}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
